import './App.css';
import React from 'react';
import MainContent from './MainContent';


function App() {
    return (
        <MainContent />
    );
}

export default App;
